import React, { useEffect } from 'react';
import './styles.scss';
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { routesConfig } from './const';
import ProtectedRoute from './ProtectedRoute';

type Props = {
  children?: React.ReactNode;
};

const isAuthenticated = !!localStorage.getItem('refreshToken');

export default function Navigation(props: Props) {

  return (
    <BrowserRouter>
      {props.children}
      <Routes>
        {routesConfig.map(route => (
          route.isProtected ? (
            <Route
              key={route.path}
              path={route.path}
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <route.component />
                </ProtectedRoute>
              }
            />
          ) : (
            <Route
              key={route.path}
              path={route.path}
              element={<route.component />}
            />
          )
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export { ROUTES } from './const';
