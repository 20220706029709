import React, { useEffect, useState } from 'react';
import './styles.scss'
import WrapperPage from '../../components/WrapperPage';
import Input from '../../components/UI/Input';
import { VisaImg } from '../../assets';
import Button, { EButtonType } from '../../components/UI/Button';
import { useTheme } from '../../provider/ThemeProvider';
import Header from '../../components/Header';
import { fetchRequisites, saveRequisite } from '../../api/requisite';
import { PaymentSystems } from '../../utils/choices';

type Props = {}

export default function WithdrawalRequisitiesPage(props: Props) {
  const [newRequisites, setNewRequisites] = useState<string | undefined>(undefined);
  const [requisites, setRequisites] = useState<any | null>(null);
  const [error, setError] = useState<string | null>(null); // Добавляем состояние для ошибки





  useEffect(() => {
    fetchRequisites()
      .then(response => {
        console.log(response,'response')
        if (response && response[0].title) {
          setRequisites(response[0]);
          setNewRequisites(response[0].title);
        }
      })
      .catch(err => {
        setError(err.message || 'Не удалось загрузить реквизиты');
      });
  }, []);

  const {theme} = useTheme();
  const isDarkTheme = theme === 'dark';

  return (
    <div className={`withdrawal-page withdrawal-requisities-page-wrapper ${isDarkTheme ? 'dark-theme' : ''} ${requisites?.show ? 'show' : "" }`}>
      <div className="hide-on-mobile">
        <Header disableContainer isAuth/>
      </div>
      <WrapperPage>
        <div className="withdrawal-page withdrawal-requisities-page">
          <h2>Withdrawal requisites</h2>
          <div className="form">
            {requisites?.icon && <img src={PaymentSystems[requisites?.icon - 1]} alt="PeesIcon" height={48}/>}
            <Input
              placeholder="0000 0000 0000 0000"
              value={newRequisites}
              disabled
            />
            {/*<Button*/}
            {/*  label="Send the details"*/}
            {/*  onClick={onHandleEditProfile}*/}
            {/*  fullWidth*/}
            {/*  variant={EButtonType.BUTTON_PRIMARY}*/}
            {/*/>*/}
          </div>
        </div>
      </WrapperPage>
    </div>
  );
}
