import React, { useState, useRef, useEffect } from 'react';
import { countries } from 'countries-list';
import './styles.scss';
import Input from '../Input';

type Props = {
  value: string;
  placeholder: string;
  onCountrySelect: (value: string) => void;
  errorMessage?: string | null;
  disabled?: boolean;
};

function findCountryCodeByObject(countries: any, targetCountry: any) {
  for (const code in countries) {
    const country = countries[code];
    if (JSON.stringify(country) === JSON.stringify(targetCountry)) {
      return code;
    }
  }
  return null;
}

export default function CountrySelect({
  value,
  placeholder,
  onCountrySelect,
  errorMessage,
  disabled,
}: Props) {
  const [active, setActive] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<{ name: string; code: string } | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleCountrySelect = (name: string, code: string) => {
    setSelectedCountry({ name, code: code.toLowerCase() });
    onCountrySelect(code.toLowerCase());
    setActive(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setActive(false);
    }
  };

  useEffect(() => {
    if (value) {
      const country = (countries as any)[value.toUpperCase()];
      if (country) {
        setSelectedCountry({ name: country.name, code: value.toLowerCase() });
      }
    } else {
      setSelectedCountry(null);
    }
  }, [value]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="input-wrapper-custom">
      <div ref={dropdownRef} className={`countries-dropdown ${active ? 'active' : ''}`}>
        <div onClick={disabled ? () => {} : () => setActive(!active)} style={{ cursor: 'pointer', zIndex: 10 }}>
          <Input
            disabled={disabled}
            placeholder={placeholder}
            value={selectedCountry?.name || ''}
            endIcon={
              selectedCountry
                ? `https://media.api-sports.io/flags/${selectedCountry.code}.svg`
                : ''
            }
          />
        </div>
        {active && (
          <div className="countries-dropdown__dropdown">
            {Object.entries(countries).map(([code, country]) => (
              <div
                key={code}
                className="countries-dropdown__dropdown-item"
                onClick={() => handleCountrySelect(country.name, code)}
              >
                <img loading="lazy" src={`https://media.api-sports.io/flags/${code.toLowerCase()}.svg`} alt={`${country.name} flag`} />
                <p className="title">{country.name}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}
